<template>
  <div>
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="
          _clearPagination();
          getList();
        "
      ></search-reset>
    </l-toggle>
    <dynamic-table
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="getList"
    ></dynamic-table>

    <!-- 新增层 -->
    <warehouse-add ref="WarehouseAdd" @refreshTable="getList"></warehouse-add>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import SearchReset from '@/components/SearchReset.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import WarehouseAdd from './WarehouseAdd.vue';
import api from '@/api/StorageApi';
import baseMixin from '../../mixins/base-mixin';
import { provinceAndCityData } from 'element-china-area-data';

export default {
  name: 'WarehouseList',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
    WarehouseAdd,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '货仓编号',
          type: 'text',
          name: 'code',
          placeholder: '请输入',
        },
        {
          label: '货仓状态',
          type: 'select',
          name: 'status',
          placeholder: '请选择',
          options: this.filterDictionary('0124'),
        },
        {
          label: '货仓归属地',
          type: 'select-province-city',
          name: 'provinceCityText',
          options: provinceAndCityData,
          placeholder: '请选择',
        },
      ],
      columns: [
        {
          type: 'index',
          fixed: 'left',
        },
        {
          label: '货仓编号',
          prop: 'warehouseCode',
        },
        {
          label: '货仓地区',
          prop: 'provinceCity',
        },
        {
          label: '货仓状态',
          prop: 'status',
          dictTranslate: true,
          dictCode: '0124',
        },
        {
          label: '关联最新的库存单号',
          prop: 'storageCode',
        },
        {
          label: '状态更新时间',
          prop: 'updateTime',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          actions: [
            {
              title: '查看订单详情',
              type: 'text',
              icon: 'el-icon-view',
              id: 'see',
              condition: '!!row.orderId',
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [
        {
          title: '添加货仓',
          type: 'primary',
          icon: 'el-icon-plus',
          event: this.addWarehouse,
        },
      ],
      computed: {},
    };
  },
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          isPage: 1,
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getWarehouseList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    refreshTable() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    viewOrderDetail(row) {
      if (row.orderId && row.status === '1') {
        console.log(row.orderId);
        const routeUrl = this.$router.resolve({
          path: `/ValuationItemEdit/${row.orderId}`,
        });
        window.open(routeUrl.href, '_blank');
      } else {
        this.$message.error('仓库已空仓，没有对应订单信息');
      }
    },
    addWarehouse() {
      this.$refs.WarehouseAdd.dialogVisible = true;
      this.$refs.WarehouseAdd.dialogTitle = '添加货仓';
    },
  },
};
</script>
