<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DynamicForm from '@/components/DynamicForm.vue';
import api from '@/api/StorageApi';
import { provinceAndCityData } from 'element-china-area-data';

export default {
  name: 'WarehouseAdd',
  components: {
    DynamicForm,
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '添加货仓',
      loading: false,
      model: {},
      formItems: [
        {
          label: '货仓归属地',
          type: 'select-province-city',
          name: 'provinceCityText',
          options: provinceAndCityData,
          placeholder: '请选择',
        },
        {
          label: '货仓编号',
          type: 'text',
          name: 'warehouseCode',
          placeholder: '系统自动生成',
          disabled: true,
        },
        {
          label: '货仓添加人',
          type: 'text',
          name: 'creatorId',
          placeholder: '系统自动生成',
          disabled: true,
        },
      ],
    };
  },
  watch: {},
  methods: {
    submitForm() {
      const { model } = this;
      const ext = {
        sysUserId: this.$store.state.login.userInfo.userId,
        ...model,
      };
      api
        .addWarehouse(ext)
        .then(() => {
          this.dialogVisible = false;
          this.$emit('refreshTable');
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dialogClosed() {
      this.model = {};
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
